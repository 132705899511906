.stepHandle{
    background-color: #338;
    color: #aaa;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    padding-top:3px;
    height:32px;
}
.stepRow{ 
    margin-bottom: 6px;
    height:48px;
}
.stepIndex{
    color: #aaa;
    padding-top:5px;
    background-color: #ddf;
    height:32px;
}
.stepActions{
    transition: 500ms;
    opacity:0;
}
.stepRow:hover .stepActions, .stepRowMsg:hover .stepActions{ 
    opacity: 1; 
    transition: 500ms;
}

.handleMe{cursor:pointer;min-height: 32px;}