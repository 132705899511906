@import '~antd/dist/antd.css';
/*  https://fonts.google.com/specimen/Heebo?selection.family=Heebo  */ 

html{ font-family: 'Heebo', sans-serif; }
body, html, #root, .ant-layout{ background:#f0f2f5; min-height:100%}
.fullHeight{ height:100%; }
.fullWidth{ width:100%; }
.centerContent{ text-align:center; }
.rightContent{ text-align:right; }
.b{ font-weight:bold; }
.border{ border:1px solid #ddd; border-radius: 6px; }
.pad12{ padding:12px; }

.formSection{background:#F5F7FD; padding:12px; border-radius:6px;}

.inputLabel{ position: relative; font-size: 12px; line-height: 12px}
.inputError{ font-size:12px; font-weight:bold; position:relative; top:8px;left:8px; line-height:0; color:#FB9C25;}
.linkText{ color:#FB9C25; cursor:pointer; font-size:12px; font-weight: bold}
.linkText:hover { color:#FBAA45; cursor:pointer; font-size:12px; font-weight: bold}

.navmenu-linkText, .ant-menu.ant-menu-dark { color:#A0AEED; font-weight: bold; cursor: pointer; margin-left:40px;}
.navmenu-linkText:hover { color:#FBAA45; font-weight: bold; cursor: pointer; margin-left:40px;}

.ant-modal-title{ font-size:22px; font-weight: normal;}

.ant-btn, .ant-btn:focus{ border:1px solid #FB9C25 ; color: #FB9C25; }
.ant-btn:hover{ border:1px solid #FBAA45; color:#FBAA45 ; }

.ant-btn-primary, .ant-btn-primary:focus{ background:#FB9C25; color:#fff; border:none;}
.ant-btn-primary:hover { background:#FBAA45; color:#fff; border:none;}

.ant-layout-header, .ant-menu.ant-menu-dark{ background:#415BDB; color:#fff;}

.contentPane{ background:#F9FAFE; padding:20px; height: 100%;}

.testdiv{background:#f00;}

.container{ background:#fff; padding:0; border:1px solid #ddd; border-radius: 6px; position:relative;}

.ant-table-thead > tr > th{ background: #fff; color:#9B9B9B; font-size:12px; line-height:12px; padding-bottom:8px;}
.ant-table-tbody > tr > td { background:#fff; color:#000; }
.ant-table-tbody > tr > td:first-child { font-weight:bold; }

#logo{ height:44px; }
#achievementsInner{ width:1520px; }
#achievementsOuter{ overflow-x: scroll; overflow-y: hidden; }
.badgeTitle{ margin-top:12px; font-weight: bold; color:#000; }
.badgeDesc{ font-size:9.5px; }
.badge{ width:190px; text-align:center;}
.badgeIcon{ position: absolute;  bottom:54px; right:30px; }

.ant-dropdown-link { color:#fff; }
.ant-dropdown-link:hover { color:#FBAA45; }


.StripeCheckout { margin:12px; }

.hero{ 
    background-size: cover;
    height:600px; 
    position: relative;
}
.hero-msg{

  padding-left:50px; 
  padding-bottom:150px; 

}
.darkForm .ant-form-item-label > label{ color:#fff; font-size:18px; font-weight:bold; }
.notnavhead{ background: unset; }
.darkBlue{ background:#0E163B; color: #fff; padding:40px;}
.blueRow{ background:#415BDA; color: #fff; padding:40px;}
.blueRow .landingFeature { height:64px; margin-top:40px;}
.darkBlue .landingFeature { height:42px; margin-top:40px;}
.blueRow .landingFeatureLabel, .darkBlue .landingFeatureLabel { font-size:20px; font-weight:bold; margin:12px;}
.blueRow img {max-width: 400px}
.landingWhite{padding:40px;}
.landingWhite img{max-width:70%;}
.landingWhite h1, .darkBlue h1{ font-size:36px; font-weight:bold;}
.darkBlue h1{color:#fff;}
.darkBlue h2{color:#aaa;}
.pad40{ padding:40px; }
.landingLarge{ font-size:24px; font-weight:bold;}
.hero-msg h1{
    color:#fff;
    font-size:48px;
    font-weight:bold;
    margin-bottom:0;
}

.notnav-btn{ color:#fff; border:none; font-weight: bold; font-size:16px; }
.notnav-btn:hover {border:none;}

#contact a, #contact a:hover, #contact a:visited{ color:#000; }

.hero-msg .txt{ color:#fff; font-size:20px;}
.hero-msg button{ margin-top:12px; font-weight:bold;}
.sectionBtn{ margin-bottom:8px; font-size:12px;}
.ngss{ background: #2DD6C1; color:#fff; font-weight:bold; text-align: center; font-size:16px; padding:12px; }
.partnerships{padding:50px;}
.partnerships h1{ font-size:32px; font-weight:bold; }
.raisedBox{ box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
.partnerships .raisedBox{padding:12px; text-align: center;}
.partnerships .raisedBox h2{ color:#415BDB; font-weight: bold; }
.landingPanelContent{ padding:50px; }
.starBack h1{ color:#fff; font-size: 32px; font-weight:bold;  text-align: center;}
.starBack .txt{ color:#fff; font-size:20px; text-align: center;}
.landingContent{ padding:50px; }
.landingContent h1{ font-size:32px; font-weight: bold; }
.landingContent h3{font-weight:bold;}
.landingWhite ul,.landingContent ul {
  list-style: none; /* Remove default bullets */
}

.landingWhite ul li::before, .landingContent ul li::before {
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: orange; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */ 
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
.landingImg{ max-height:75%; max-width:75%; }

.ant-table-pagination.ant-pagination { margin:16px; float:right; }

#expStepOpt .ant-list-split .ant-list-item:last-child { border-bottom: 1px solid #ddd; }
#expStepOpt .ant-list-item{ border: 1px solid #ddd; border-radius:3px;  margin:6px; padding:12px;}
.expOptions{position:relative; max-height: 82px; cursor: pointer}
.expOptions img{margin-right:12px;}
#expStepOpt .ant-list-item.expOptions:hover{ border: 1px solid #FBAA45; }
#expStepOpt .ant-list-item.selected{border: 1px solid #FBAA45;}
.expOptions.selected .icon path:nth-child(2), .expOptions.selected .icon circle{ fill:#FBAA45; }

.expOptTitle{ color:#000; font-weight:bold; }
.expOptDesc{ display:block; color:#666; font-size:12px;}
.expOptDescription{ display:inline; }
.ant-list-item .expOptions,.ant-modal-content .ant-list-grid .ant-list-item { display:flex; }
.expOptions.inactive{ opacity: 0.3 }

.circleInactive{position:absolute; top:3px; right:3px;}
.circleInactive path:nth-child(2){ fill:#aaa;}
.circleInactiveLeft{position:absolute; left:3px;}
.circleInactiveLeft path:nth-child(2){ fill:#aaa;}


.question{ margin-top:12px; font-weight: bold; }
.answer{ position: relative; margin-top:6px; padding:6px 40px; border:1px solid #ddd; width:160px; font-weight:bold; border-radius: 3px}
.answer:hover{ border:1px solid #FBAA45; color:#FBAA45 ; }
.answer:hover .circleInactiveLeft path:nth-child(2){ fill:#FBAA45;}
.answer.selected { border:1px solid #FBAA45; }
.answer.selected path:nth-child(2), .answer.selected circle{ fill:#FBAA45; }

.icon{ width:24px; height:24px; }

.subHeader{ background:#F9FAFE; border-bottom: 1px solid #ddd; color:#666;}
.subHeader .ant-btn{ margin-right:12px; }
#subHeaderDetails div{line-height:24px; padding-left:12px;}
#closeSubMenu{ color:#aaa; border-left: 1px solid #aaa; }

.largeContent{ padding:40px; background:#fff;}
.subPane{ background:#fff; margin-top:16px; padding:12px; border: 1px solid #ddd; height:100%;}

.docIcon svg{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    vertical-align: middle;
    padding: 6px;
} 
.startCard .docSubTitle{ font-weight:bold; padding-top:12px; }
.ant-card-actions{ vertical-align: center; }
.blueBox{ background:#415BDB; border-radius:3px; min-height:200px; padding:20px;}
.blueBoxTitle{ font-weight:bold; color:#fff; font-size:20px; }
.blueboxSubText{ color:#93A2EA; font-size:14px;}
.blueBox button{margin-top:20px; margin:10px;}
.blueBox input, .blueBox .ant-select-selection{ background:#5C73E0; color:#fff; font-weight:bold; }
.stepRow{ margin-bottom:9px; }
.stepRowMsg{ margin-bottom:9px; height:104px;}
.blueBox .ant-select-arrow-icon path{ fill: #fff; }
.blueBox.boxSteps{ border-top-right-radius: 0; border-bottom-right-radius: 0; }
.aboveBox{ margin-bottom:12px; }
.tab{padding-left:20px;}

.codeBox{ background:#202D6D; color:#fff; padding:20px; min-height:200px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; }

.ant-input-group.ant-input-group-compact, .ant-input-group.ant-input-group-compact:before, .ant-input-group.ant-input-group-compact:after { display:inline; }
.stepIcon path:nth-child(2){ fill:#A0AEED; }

.stepArrow path{ fill:#A0AEED; }
.stepIcon, .stepArrow{ display:inline-block; margin-top:5px; cursor:pointer;}

.addStepIcon{ font-size:24px; background: #5C73E0; width: 36px; color:#fff; margin:auto; margin-bottom:12px; cursor:pointer;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    vertical-align: middle;
    padding: 4px;}

.codeBlock{padding-left:40px;}
.codeHighlight{ color:#FB9C25; }
.codeComments{ color:#aaa; line-height:16px;}
.codeStep{ margin-top:10px; font-weight:bold;}

.pageDescription{ color:#333; padding-left:40px; }

.templates .expOptions{ border: 1px solid #ddd; border-radius:3px;  margin:6px; padding:12px;}
.codeExplains .ant-row{ background:#F5F7FD; border-radius:6px; margin-top:6px; font-size:14px; line-height:16px;}
.codeExplains .ant-col-12{ padding:12px; }
.codeExample{ border-left: 2px solid #E7EBFB; color: #546CDF; }
.codeDescTitle{color:#252626; font-weight:bold; margin-bottom:12px;}
.codeDescText{color:#999; }

.marginTop{margin-top:20px;}

.stepCompleteIcon{ position:absolute; right:12px; bottom:12px; font-size:20px;}
.stepCompleteIcon{ color:#aaa;}
.completed .stepCompleteIcon { color:#00f;}

.Convection-Docs .docIcon svg, .Convection-Docs .docIcon svg path:nth-child(2){ background: #F9D9FE; fill:#E054FB;}
.Radiation-Docs .docIcon svg, .Radiation-Docs .docIcon svg path:nth-child(2){ background: #E0F9F6; fill:#2DD6C1;}
.Conduction-Docs .docIcon svg, .Conduction-Docs .docIcon svg path:nth-child(2){ background: #E6F7FE; fill:#5FCAF8;}
.Video-Docs .docIcon svg, .Video-Docs .docIcon svg path:nth-child(2){ background: #FFD8F0; fill:#FF009D;}
.docBoxRow{ margin-top:16px; }
.docTextWrapper{ display:inline-block; margin-left:20px}
.btnSet .ant-btn{margin-left:12px;}
.experimentNav a{ color:#FB9C25;}
.experimentNav a div{padding-left:6px;}
.experimentNav a.active{ border-left: 3px solid #FB9C25;}
.experimentNav a.active div{display:inline;}
.experimentNav a:hover{ color:#FBAA45;  }
.futureStep{ padding-left:6px; color:#999; }
.prepStep{margin-top:12px; width:100%;}
.prepStep img {height:200px;margin-top:12px;}
.bigIcon{ height:50px;width:50px; }
#uploadBox{ margin-top:24px; }
.highcharts-container{width: 100%}
#experimentHeader img{ margin-right: 12px; position:relative; }
#expTitle{ position:absolute; top:-12px; left:65px; font-weight:bold; color:#000;}
#expPhase{ position:absolute; bottom:-12px; left:65px; }
.inline{ display:inline; }
.uploadBox{ font-size:24px; padding:40px; background: #F5F7FD; border: 1px dashed #aaa; border-radius:6px; margin-top:20px;}
.navmenu-linkText.active, .ant-menu.ant-menu-dark .ant-menu-item-selected{ color:#FB9C25; background:transparent}
#videoInput{ background:#415BDB; color:#fff; font-weight:bold; border-top-left-radius: 6px; border-bottom-left-radius: 6px; padding:12px; height: 420px}
#videoInput textarea { margin-top:12px; height:340px;  }
#videoPreview{ background:#202D6D; color:#fff; font-weight:bold; border-top-right-radius: 6px; border-bottom-right-radius: 6px; padding:12px; min-height: 420px; font-family: 'Press Start 2P', cursive; text-shadow: 0 0 5px #5CCFB7;}
#videoPreviewMessage{ font-family: "Lucida Console", "Courier New", monospace; background:#000; margin:6px; padding:6px; font-size:24px; border-radius:6px; height:340px; color:yellowgreen }
#spaceStatus{ height:300px; font-size:24px; color:#fff;padding:12px; font-weight: bold; text-align: center;}
#spaceStatus .ant-statistic{ margin:auto;width:400px;background-color: #000; opacity: 0.7; border: 1px solid #878A9A; border-radius:6px; color: #878A9A; padding:12px; margin-top:24px; margin-bottom:24px; font-size:14px; font-weight: normal }
#spaceStatus  .ant-statistic-title{font-size:14px; color:#fff;}
#blueNote{ color:#8798E8; font-size:12px; margin-top:8px; }
.countdownNumber{ display:inline; color:#F4AF24; font-size:42px; font-weight:bold; width:55px;}
.countdownDivider{ display:inline;font-size:42px; margin:12px;}
.countdownLabel{ display:inline; width:55px;margin:0 16px;position:relative; top:-12px; }
.dataStats{ font-weight:bold; }
.dataStats .int{ background:#F5F7FD; padding:6px; margin-right:12px; }
#root{min-height:100%;padding-bottom:20px;}
.imgModal{ max-height:50%; max-width:50%; }
div.prediciton{background:#F5F7FD; margin:3px; padding:6px;}
.reportQuestion {margin-bottom:12px;}
.reportQuestion div{ margin-left:9px; margin-top:3px;}
.reportQuestion .b {color:#000; margin-left:0;}
.bigTemp{ font-size:36px; color:#000; }
.tempLabel{ font-size:12px; color:#aaa; font-weight: bold; }
.grey{ color:#aaa; }
.reportImage{height:100px; width:100px;}
.materials img{ max-height: 400px; max-width: 400px;  }
.materialList {margin:20px}
.materialList tr:first-child { border:1px solid #aaa; }
.materialList th{ padding:6px; color:#666; }
.materialList td{ padding:6px; border:1px solid #aaa; }
.experimentNavSection{ border-bottom: 1px solid #aaa; margin-top:12px; color: #415BDA; font-weight: bold; }
video{ border: 20px solid #405BDB; border-radius:10px;}
audio{ height:auto; }
.codeBox video{ margin-left:0; border: 20px solid #405BDB; border-radius:10px; background: #3F5ADB;}

.codeFormLabel{ position:relative; top:-50px; color:#93A2EA; }
.codeFormLabelMsg{ position:relative; top:-112px; color:#ddd; }
.codeFormLabel div, .codeFormLabelMsg div{ display:inline-block; }

tr:hover .showOnParentHover{ display:unset; ; }
.showOnParentHover{ display: none; }

.headRow{ font-weight:bold; color:#999; border-bottom:1px solid #999;margin-bottom:12px }
.expOptDescription{width:100%;}
.statusTags .ant-tag{ margin-right:0}

.ant-card{ border-radius: 6px; }
.ant-card-head, .ant-card-extra{
  background: rgb(101, 122, 225);
  color:#fff;
  border-radius:6px 6px 0 0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title { color:#fff; font-weight:bold; }
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{ color:#fff;  }
.ant-steps{ margin-bottom:12px; }
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{ color:#333}
ul{
  padding-inline-start: 10px;
}