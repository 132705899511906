.codeBlock{ padding-left:12px; }
.codeLowLight, mark.codeLowLight{ 
    background: transparent;
    color:#aaf; 
}
.codeComments{ 
    background: transparent;
    color:#888; 
}

.codePreview .ant-card-body{
    background-color: #001529;
    color:#fff
}